import React from 'react'
import './AboutusSection.css'
import { Col, Container, Row } from 'react-bootstrap'
function AboutusSection() {
    return (
        <>
            <section className='aboutt_section my-5'>
                <Container>
                    <div className='aboutt_main  py-4'>
                        <div className='mb-3'>
                            <h3 className='semi_title text-center mb-0'>About Us</h3>
                            <div className='mainbox'>
                                <div className='boxxx'></div>
                            </div>

                        </div>
                        <div className='textdivv'>
                        <p className='text text-center '>Welcome to Short up News—your go-to source for quick, concise, and reliable news in Marathi. We understand the value of your time, so we deliver the latest updates in a format that's easy to read and digest, whether you're at home, at work, or on the go.</p>

                        <p className='text text-center '>Our mission is to keep you informed with the most important stories from across Maharashtra, district by district. Whether it's local happenings, regional developments, or significant events that impact your community, we cover it all with precision and clarity.</p>

                        <p className='text text-center '>At Short up News, we believe that staying informed shouldn’t be time-consuming. That’s why we bring you the news in a simplified format, focusing on what matters most, without the clutter.</p>

                        <p className='text text-center '>Stay connected, stay informed, and stay ahead with Short up News—because your time is valuable, and so is the news.</p>
                        </div>

                        

                        <div className='py-4'>
                            <Row className='justify-content-center'>
                                <Col xxl={5} xl={5} lg={6} md={6} sm={6} className='p-lg-0'>
                                    <div className='ssss'>
                                        <div className='leftgroup_div' >

                                        
                                        <div className='mobimg_div mb-2'>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/homepage/Groups13.png"}
                                                className="grpimg"
                                                alt="Autowala"
                                            />
                                        </div>
                                        <p className='text'>Our mission is to keep you informed with the most important stories from across Maharashtra, district by district. Whether it's local happenings, regional developments, or significant events that impact your community, we cover it all with precision and clarity.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={2} xl={2} lg={0} md={0} sm={0} className='mb-4  clss p-lg-0'>
                                    <div className=''>
                                       

                                        
                                        <div className='mobimg_div'>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/homepage/shoutmobile.png"}
                                                className="mobile"
                                                alt="Autowala"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={5} xl={5} lg={6} md={6} sm={6} className='p-lg-0'>
                                <div className='ssss'>
                                <div className='rightgroup_div'>
                                        <div className='mobimg_div mb-2'>
                                            <img
                                                src={process.env.PUBLIC_URL + "/assets/images/homepage/Groups14.png"}
                                                className="mobile"
                                                alt="Autowala"
                                            />
                                        </div>
                                        <p className='text'>we envision a world where informed citizens shape a brighter future through access to truthful, comprehensive, and insightful journalism. We aim to be the leading source of news that not only reports facts but also fosters understanding, promotes accountability, and inspires positive change</p>

                                    </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default AboutusSection