import "./banner/Banner.css";
import Banner from "./banner/Banner";

import LatestNews from "./LatestNews/LatestNews";
import OurImpact from "./OurImpact/OurImpact";
import AboutusSection from "./AboutusSection/AboutusSection";
import Contactus from "./ContactUs/Contactus";
import StayUpdated from "./StayUpdated/StayUpdated";
import OurValue from "./OurValue/OurValue";
import Download_App from "./Download_App/Download_App";

function Home() {
  return (
    <>
      <StayUpdated />

      <Download_App />

      <div id="About_Us">
        <AboutusSection />
      </div>
      <div id="OurValue">
        <OurValue />
      </div>
      {/* <div id="OurImpact">
        <OurImpact />
      </div> */}
      <div id="Download_App">
        <LatestNews />
      </div>
      <div id="Contact">
        <Contactus />
      </div>
    </>
  );
}

export default Home;
