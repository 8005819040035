import React, { useState } from "react";
import "./Download_App.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
function Download_App() {
  return (
    <>
      <section className="Download_App">
        <Container>
          <div className="text-center mt-4">
            <p className="downlotext">Download the app</p>
            <Link to={"https://play.google.com"} target="_black">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/homepage/ggply.png"
                }
                className="staygrpimg me-3"
                alt="Autowala"
              />
            </Link>
            <Link to={"https://www.apple.com"} target="_black">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/Aboutus/AppStore.png"
                }
                className="staygrpimg"
                alt="Autowala"
              />
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Download_App;
