import React from 'react';
import './OurValue.css';
import { Col, Container, Row } from 'react-bootstrap';

const values = [
    { id: 1, number: '01', title: 'Clarity and Precision', description: 'We are committed to delivering news in a clear, concise, and straightforward manner. Our focus is on providing essential information without unnecessary details, making it easier for you to stay informed quickly' },
    { id: 2, number: '02', title: 'Local Focus:', description: 'We prioritize district-wise news, ensuring that every region in Maharashtra is covered. Our goal is to keep you connected to whats happening in your community and across the state.' },
    { id: 3, number: '03', title: 'Reliability:', description: 'Trust is at the heart of our service. We strive to provide accurate and verified news, so you can rely on Short up News to keep you informed with factual and timely updates.' },
    { id: 4, number: '04', title: 'Accessibility:', description: 'We believe news should be accessible to everyone. By offering news in the Marathi language, we aim to reach a wider audience, ensuring that language is never a barrier to staying informed.' }
];

function OurValue() {
    return (
        <>
            <section className='ourvalue_section'>
                <Container>
                    <div className='ourvalue_main'>
                        <h3 className='semi_title text-center mb-2'>Our Values</h3>
                        <div className='mainbox'>
                            <div className='boxxx'></div>
                        </div>
                        <p className='text text-center mt-4 mb-5'>Your Trusted Source for Unbiased, In-Depth News Coverage</p>
                        <div className='cardds_div'>
                            <Row>
                                {values.map((value) => (
                                    <Col key={value.id} xxl={3} xl={3} lg={4} md={6} sm={6} className='mb-3'>
                                        <div className='card_div'>
                                            <div className='card_sub_div'>
                                                <div className='text_div'>
                                                    <p className='text_title1 mb-0'>{value.number}</p>
                                                </div>
                                                <p className='text_title'>{value.title}</p>
                                                <p className='text'>{value.description}</p>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default OurValue;
