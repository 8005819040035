import React from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import Privacy from "./components/Privacy/Privacy";
import Terms_condition from "./components/terms-condition/Terms_condition";

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    console.log(section);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth'});
    }
};
  return (
    <>
      <ScrollToTop />
      <Header  scrollToSection={scrollToSection}/>
      <Routes>
        <Route path="/" element={<Home  scrollToSection={scrollToSection}/>} />
        <Route path="/privacy-policy" element={<Privacy/>} />
        <Route path="/terms-condition" element={<Terms_condition/>} />
      </Routes>
      <Footer scrollToSection={scrollToSection} />
    </>
  );
}

export default App;
