import React from "react";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import { Link, useNavigate } from "react-router-dom";
import {
  faEnvelope,
  faHeadphonesSimple,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Header({ scrollToSection }) {
  const navigate = useNavigate();

  const handleNavigateAndScroll = (e, sectionId) => {
    e.preventDefault();
    navigate("/");
    setTimeout(() => {
      scrollToSection(sectionId);
    }, 100); // Adjust the delay if necessary
  };

  return (
    <>
      <section className="nav_section">
        <div className="topheader">
          <Container>
            <div className=" topheadermain">
              <div className="leftsidee">
                <p className="toptext ttteex mb-0 pe-sm-3 px-1">
                  <FontAwesomeIcon icon={faEnvelope} className="me-1" />{" "}
                  shortupnews1@gmail.com
                </p>
                <p className="toptext mb-0 ps-sm-3 px-1">
                  <FontAwesomeIcon icon={faHeadphonesSimple} className="me-1" />{" "}
                  +91 9970033115
                </p>
              </div>
              <div className="d-flex ">
                <div className="me-3">
                  <Link to="https://www.facebook.com/" target="blank">
                    {" "}
                    <div className="brandicndiv">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </div>
                  </Link>
                </div>
                <div className="me-3">
                  <Link to="https://in.linkedin.com/" target="blank">
                    {" "}
                    <div className="brandicndiv">
                      <FontAwesomeIcon icon={faInstagram} />
                    </div>
                  </Link>
                </div>
                <div className="me-3">
                  <Link
                    to="https://www.youtube.com/@ShortUpNews"
                    target="blank"
                  >
                    {" "}
                    <div className="brandicndiv">
                      <FontAwesomeIcon icon={faYoutube} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="">
            <Navbar expand="lg" className="">
              <Navbar.Brand href="#">
                <div className="navimg_div">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/logos/ShortUplogo.png"
                    }
                    className="navimg"
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="navvvlinkss mx-auto my-2">
                  <Link to={"/"}>
                    <Nav.Link>Home</Nav.Link>
                  </Link>
                  <Nav.Link
                    onClick={(e) => handleNavigateAndScroll(e, "About_Us")}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    onClick={(e) => handleNavigateAndScroll(e, "Contact")}
                  >
                    Contact
                  </Nav.Link>
                </Nav>
                <div>
                  <Link to="">
                    {" "}
                    <Common_Button
                      text="Download App"
                      className="navbutton"
                      onClick={(e) =>
                        handleNavigateAndScroll(e, "Download_App")
                      }
                    />
                  </Link>
                </div>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Header;
