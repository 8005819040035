import React from 'react'
import './Contactus.css'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Common_Button from '../../CommonComponents/Button_Common/Button_Common'
function Contactus() {
    return (
        <>
            <section className='contact_section '>
                <Container>
                    <div className='contact_main'>

                        <Row>
                            <Col lg={6}>
                                <div className='contsimgmin'>
                                   <div className='contactimgdiv'>
                                   <img
                                            src={process.env.PUBLIC_URL +  "/assets/images/homepage/shoutgirll11.png"}
                                            className="mwala"
                                            alt="shoutup"
                                        />
                                   </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='formdivv'>
                                    <h3 className='semi_title mb-3'>Contact Us</h3>
                                    <p className='text'>Let's Start a Conversation!</p>

                                    <div className='frmmdiv'>
                                        <Form>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">

                                                <Col sm="12">
                                                    <Form.Control type="text" placeholder="Enter Your Name" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formPlaintextPassword">

                                                <Col sm="6" className="mb-3">
                                                    <Form.Control type="email" placeholder="Enter your email" />
                                                </Col>
                                                <Col sm="6" className="mb-3">
                                                    <Form.Control type="number" placeholder="Enter your Number" />
                                                </Col>
                                            </Form.Group>
                                            {/* <Form.Check 
                                            
                                            className='text mb-3'
                                                type="checkbox"
                                                label="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis"
                                            /> */}

                                            <Common_Button text="Submit" className="navbutton my-4" />

                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className='backcolorr'></div>
                    </div>
                </Container>

            </section>
        </>
    )
}

export default Contactus