import React from 'react'
import './LatestNews.css'
import { Col, Container, Row } from 'react-bootstrap'
function LatestNews() {
    return (
        <>

            <section className='news_section mt-5'>
                <Container>
                    <div className='news_main'>
                        <div className='row rrwww'>
                            <div  className='col-lg-6 col-md-6  col-12 my-auto'>
                                <div className='news_text_div'>
                                    <h3 className='semi_title'>Get The Latest News <br /> Anywhere, Anytime</h3>
                                    <p className='downloadtext mt-3 mb-5'>- Download Our App</p>

                                    <div className="button_div">

                                        <button className="buttonclasses me-4"> <img src={process.env.PUBLIC_URL + "/assets/images/homepage/ggply.png"} className=" buttonimaged" /></button>
                                        <button className="buttonclasses"> <img src={process.env.PUBLIC_URL + "/assets/images/homepage/appstore.png"} className=" buttonimaged" /></button>
                                    </div>


                                </div>
                            </div>
                            <div   className='col-lg-6 col-md-6 col-12'>
                                <div className='newsimages pb-2'>
                                    <div className='newimg_div text-center'>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/homepage/Group23332.png"}
                                            className="rightnewimga"
                                            alt="Autowala"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

        </>
    )
}

export default LatestNews