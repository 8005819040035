import React from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  faEnvelope,
  faHeadphonesSimple,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
const Footer = ({ scrollToSection }) => {
  const navigate = useNavigate();

  const handleNavigateAndScroll = (e, sectionId) => {
    e.preventDefault();
    navigate("/");
    setTimeout(() => {
      scrollToSection(sectionId);
    }, 100); // Adjust the delay if necessary
  };
  return (
    <>
      <section className="footter_section">
        <Container>
          <div className="footter_main">
            <div className="top_content_div">
              <Row>
                <Col xxl={6} xl={7} lg={8} md={9} sm={11} className="mx-auto ">
                  <div className="contmain mb-5">
                    <h3 className="connecttext">Connect Now, Stay Updated</h3>
                  </div>
                  <div className="inputfieldd mb-4">
                    <Form.Control type="email" placeholder="Enter email" />
                    <Common_Button
                      text="Subscribe"
                      className="subscribebutton"
                    />
                  </div>

                  <div className="links_div mb-2">
                    <Link to="/">
                      <p className="text footerlink">Home</p>
                    </Link>
                    <p
                      className="text footerlink"
                      onClick={(e) => handleNavigateAndScroll(e, "About_Us")}
                    >
                      About Us
                    </p>
                    <p
                      className="text footerlink"
                      onClick={(e) => handleNavigateAndScroll(e, "OurValue")}
                    >
                      Our Value
                    </p>

                    <Link to="/privacy-policy">
                      <p className="text footerlink">Privacy Policy</p>
                    </Link>

                    <Link to="/terms-condition">
                      <p className="text footerlink">Terms & Conditions</p>
                    </Link>
                    {/* <p
                      className="text footerlink"
                      onClick={(e) => handleNavigateAndScroll(e, "OurImpact")}
                    >
                      Our Impact at a Glance
                    </p> */}
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    <div className="me-2">
                      <Link to="https://www.facebook.com/" target="blank">
                        {" "}
                        <div className="brandicndivs">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </div>
                      </Link>
                    </div>
                    <div className="me-2">
                      <Link to="https://in.linkedin.com/" target="blank">
                        {" "}
                        <div className="brandicndivs">
                          <FontAwesomeIcon icon={faInstagram} />
                        </div>
                      </Link>
                    </div>
                    <div className="">
                      <Link
                        to="https://www.youtube.com/@ShortUpNews"
                        target="blank"
                      >
                        {" "}
                        <div className="brandicndivs">
                          <FontAwesomeIcon icon={faYoutube} />
                        </div>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="bottomcontdiv">
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={11}
                  sm={11}
                  className="mx-auto "
                >
                  <div className="bottom_cont_main">
                    <p className="bottext mb-0">
                      © 2024 Short Up. All Rights Reserved. Developed by{" "}
                      <Link to={"https://www.profcyma.com/"} target="_black">
                        <span>Profcyma</span>
                      </Link>
                    </p>
                    <div className="leftsideed">
                      <p className="bottext ttteedd mb-0 pe-3">
                        <FontAwesomeIcon icon={faEnvelope} className="me-1" />{" "}
                        shortupnews1@gmail.com
                      </p>
                      <p className="bottext mb-0 ps-3">
                        <FontAwesomeIcon
                          icon={faHeadphonesSimple}
                          className="me-1"
                        />{" "}
                        +91 9970033115
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>

        <div className="footerimgdiv">
          <div className="immmg_div">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/logos/ShortUplogo.png"
              }
              className="footrrimg"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
