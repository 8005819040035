import React from 'react'
import { Link } from 'react-router-dom'

const Terms_condition = () => {
    return (
        <>
            <section className='privacy-main-sec'>
                <div className='container'>
                    <div className='text-center'>
                        <h3>Terms and Conditions</h3>
                    </div>

                    <h5>1. Acceptance of Terms</h5>
                    <p>By downloading, installing, or using Short up News (the "App"), you agree to be bound by these Terms and Conditions. If you do not agree with these terms, please do not use the App.</p>

                    <h5>2. Use of the App</h5>
                    <ul>
                        <li>You must be at least 18 years old to use this App.</li>
                        <li>You agree to use the App in accordance with all applicable laws and regulations.</li>
                    </ul>


                    <h5>3. User Content</h5>
                    <p>You may submit content, such as comments or feedback, through the App ("User Content"). By submitting User Content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and distribute your User Content. You agree that your User Content will not contain any unlawful, offensive, or inappropriate material.</p>

                    <h5>4. Intellectual Property</h5>
                    <p>All content, trademarks, and data on the App, including but not limited to text, graphics, logos, and software, are the property of Short up News or its licensors. You may not copy, distribute, or create derivative works from any content on the App without our prior written consent.</p>

                    <h5>5. Limitation of Liability</h5>
                    <p>The App is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the App's operation or the information, content, or materials included.We are not liable for any damages arising from the use of or inability to use the App.</p>

                    <h5>6. Termination</h5>
                    <p>We reserve the right to suspend or terminate your access to the App at any time, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of the App, us, or third parties, or for any other reason.</p>

                    <h5>7. Governing Law</h5>
                    <p>These Terms and Conditions are governed by and construed in accordance with the laws of India.</p>

                    <p>Any disputes arising out of or in connection with these Terms and Conditions will be subject to the exclusive jurisdiction of the courts of India.</p>

                    <h5>8. Changes to Terms and Conditions</h5>
                    <p>We may modify these Terms and Conditions at any time. Your continued use of the App after any changes constitutes your acceptance of the new Terms and Conditions.</p>

                    <h5>9. Contact Us</h5>
                    <p>If you have any questions about these Terms and Conditions, please contact us at <Link> contact@shortupnews.com</Link></p>
                </div>
            </section>
        </>
    )
}

export default Terms_condition