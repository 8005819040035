import React, { useState } from "react";
import "./StayUpdated.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
function StayUpdated() {
  const qualifiedData = [
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip1.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip2.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip3.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip4.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip5.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip6.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip1.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip1.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip1.png",
    },
    {
      iconSrc: process.env.PUBLIC_URL + "/assets/images/homepage/swip1.png",
    },
  ];
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  return (
    <>
      <section className="experience-app">
        <Container>
          <Row>
            <Col lg={8} md={9} className="mx-auto">
              <div className="heading-holder text-center">
                <h2 className="semi_title">Stay Updated</h2>
                <h4 className="latesttext">with the Latest News and Trends</h4>
              </div>

              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={"auto"}
                spaceBetween={0}
                coverflowEffect={{
                  rotate: 25,
                  stretch: 0,
                  depth: 0,
                  modifier: 1,
                  slideShadows: false,
                }}
                modules={[EffectCoverflow, Autoplay]}
                className="mySwiper"
                onSwiper={(swiper) => setSwiperInstance(swiper)}
              >
                {qualifiedData.map((qualification, index) => (
                  <SwiperSlide>
                    <div className=" mb-5">
                      <div className="orange-circle">
                        <img
                          className="bg-img"
                          src={qualification.iconSrc}
                          alt={`icon-${index}`}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="mobile-screen">
                  <img
                    className="mobile-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/homepage/mobilecover.png"
                    }
                    alt={"mobile-img"}
                  />
                </div>
              </Swiper>
              <div className="swippperdivv">
                <div
                  className="main-slider me-3"
                  onClick={() => handleMainSliderClick("prev")}
                >
                  <div className="main-arrow-back">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </div>
                </div>
                <div
                  className="main-slider"
                  onClick={() => handleMainSliderClick("next")}
                >
                  <div className="main-arrow-next">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  );
}

export default StayUpdated;
